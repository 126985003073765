import type { NextPage } from 'next';
import React from 'react';
import dynamic from 'next/dynamic';
import { useAppSelector } from '@/hooks/useStore';
import { selectUserFlags } from '@/redux';

const HomeV1 = dynamic(() => import('@/components/homepage-v1/HomePage'));
const HomeV2 = dynamic(() => import('@/components/homepage/HomePage'));

const Home: NextPage = () => {
  const { flags, loading } = useAppSelector(selectUserFlags);

  if (loading) return null;

  return flags.homepageV2 ? <HomeV2 /> : <HomeV1 />;
};

export default Home;
